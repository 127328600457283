'use client';
import React, { useEffect, useMemo } from 'react';
import { MainCard } from '@/components/MainCard';
import { TopCollections } from '@/components/TopCollections/TopCollections';
import { getGlobalState, setGlobalState, useGlobalState } from '@/store';
import { fromWei } from '@/services/blockchain';
import { useDispatch, useSelector } from 'react-redux';
import { collectionSelectors } from '@/store/selectors';
import { collectionActions } from '@/store/actions';
import { useWeb3Modal } from '@web3modal/ethers5/react';
import { useRouter } from 'next/navigation';

const Home = () => {
  const platform = getGlobalState('platform');

  const [connectedAccount] = useGlobalState('connectedAccount');
  const dispatch = useDispatch();
  const top5List = useSelector(collectionSelectors.top5ListSelector);
  const loading = useSelector(collectionSelectors.loadingSelector);
  const { open } = useWeb3Modal();
  const router = useRouter();

  useEffect(() => {
    const fetchData = async () => {
      dispatch(collectionActions.getTop5Collections());
    };

    fetchData();
  }, [dispatch]);

  const [nft1, nft2] = useMemo(() => {
    const now = new Date();
    const first = new Date(
      now.setDate(now.getDate() + Math.floor(Math.random() * 20)),
    );
    const seconds = new Date(
      now.setDate(now.getDate() + Math.floor(Math.random() * 20)),
    );
    first.setHours(first.getHours() + Math.floor(Math.random() * 24));
    seconds.setHours(seconds.getHours() + Math.floor(Math.random() * 24));
    return [
      {
        image: '/png/card1.webp',
        duration: first.getTime(),
        price: 1 + Number((Math.random() * 15).toFixed(2)),
      },
      {
        image: '/png/card2.webp',
        duration: seconds.getTime(),
        price: Math.floor(Math.random() * 15),
      },
    ];
  }, []);

  const handleCreateNFT = () => {
    if (platform == 'ios' || platform == 'android') {
      if (typeof window !== 'undefined') {
        window.open(
          'https://metamask.app.link/dapp/nft-marketplace-dev-rfh6iet4ea-uc.a.run.app/create-nft',
        );
      }
    } else {
      if (connectedAccount) {
        router.push('/create-nft');
      } else {
        open();
      }
    }

    setGlobalState('boxModal', 'scale-100');
  };

  return (
    <div className="home_page w-full">
      <div className="main_container relative">
        <h1 className="h-[0px] text-[transparent] relative">NFT marketplace</h1>
        <div className="mt-[77px] mb-[68px]">
          <TopCollections
            loading={loading}
            top={5}
            collections={top5List.map((el, i) => {
              const getPreparedDayVolumeChange = () => {
                if (el?.day_volume_change) {
                  return el.day_volume_change;
                }
                if (el?.volume_day && !el?.volume_prev_day) {
                  return '∞';
                }
                if (!el?.volume_day && el?.volume_prev_day) {
                  return -100;
                }
                return 0;
              };
              const preparedDayVolumeChange = getPreparedDayVolumeChange();

              return {
                ...el,
                collection: {
                  name: el.collection_name,
                  image: el.collection_avatar,
                },
                rank: ++i,
                floor_price: fromWei(el?.floor_price || 0),
                seven_day_volume: fromWei(el?.seven_day_volume || 0),
                day_volume_change: preparedDayVolumeChange,
              };
            })}
          />
          <div className="left-[-200px] top-[400px] w-[300px] h-[440px] absolute z-[-1]">
            <img
              src="/png/banner_bg_small.png"
              className="w-pull h-pull z-[-1]"
              alt="background"
            />
          </div>
          <div className="right-[-420px] top-[40px] absolute z-[-1]">
            <img
              src="/png/banner_bg.png"
              className="w-pull h-pull z-[-1]"
              alt="background"
            />
          </div>
        </div>
        <div className=" flex grow flex-row mt-[5px] mb-[108px]  justify-between relative">
          <div className="flex  flex-col gap-[50px] mt-[60px] grow-0">
            <div className="flex flex-col gap-[30px]">
              <div className="w-[532px]">
                <span className="text-white font-spaceGrotesk text-[60px] leading-[60px] font-medium">
                  EXPLORE, COLLECT & TRADE
                </span>
                <span className=" ml-[20px] text-primary font-spaceGrotesk text-[60px] leading-[60px] font-medium">
                  NFTs
                </span>
              </div>
              <div className="max-w-[324px]">
                <span className=" text-white font-archivo text-[20px] leading-[26px] font-regular">
                  Publish your NFTs to power up your infinite storyline
                </span>
              </div>
            </div>
            <div className="flex flex-row gap-[40px] ">
              <div className="flex flex-col gap-[12px]">
                <div className="w-[219px] m-w-[219px] text-wrap:wrap">
                  <p className="text-white font-spaceGrotesk text-[37px] leading-[37px] font-bold">
                    COMMUNITY DRIVEN
                  </p>
                </div>
                <div className="w-[170px] text-wrap:wrap ">
                  <p className="text-whiteBorder font-archivo text-[18px] leading-[19.8px] font-regular">
                    Anyone can publish his lore as NFTs
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-[12px]">
                <div className="w-[170px] m-w-[170px] text-wrap:wrap">
                  <p className="text-white font-spaceGrotesk text-[37px] leading-[37px] font-bold">
                    POWERED BY AI
                  </p>
                </div>
                <div className="w-[170px] text-wrap:wrap ">
                  <p className="text-whiteBorder font-archivo text-[18px] leading-[19.8px] font-regular">
                    Connected to Gemini
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-[12px]">
                <div className="w-[195px] m-w-[195px] text-wrap:wrap">
                  <p className="text-white font-spaceGrotesk text-[37px] leading-[37px] font-bold">
                    IMMERSIVE CONTENT
                  </p>
                </div>
                <div className="w-[148px] text-wrap:wrap ">
                  <p className="text-whiteBorder font-archivo text-[18px] leading-[19.8px] font-regular">
                    Interact with NFTs in your storylines
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-row gap-[12px] z-1">
              <button
                className=" w-[216px] py-[20px] px-[24px] flex flex-col items-center bg-white rounded-[18px] text-black  font-spaceGrotesk text-[16px] leading-[16px] font-semiBold hover:bg-black_button hover:text-white border transition duration-300 ease-in-out"
                onClick={handleCreateNFT}
              >
                <p className="">Create NFT</p>
              </button>
              <button
                className=" w-[216px] py-[20px] px-[24px] flex flex-col items-center bg-primary rounded-[18px] text-white  text-[16px] leading-[16px] font-semiBold shadow-primary border border-transparent font-spaceGrotesk hover:bg-[#1d1f24]  hover:text-primary hover:border-1 hover:border-primary hover:shadow-none transition duration-300 ease-in-out"
                onClick={() => {
                  router.push('/explore');
                }}
              >
                <p className="">Explore more NFTs</p>
              </button>
            </div>
          </div>
          <div className=" min-w-[562px] max-w-[562px] h-[620.33px] relative  flex flex-row items-end z-[1]">
            <div className="absolute bottom-0  z-20">
              <MainCard data={nft1} bg="dark" />
            </div>
            <div className="absolute top-0 right-0 z-10 ">
              <MainCard data={nft2} />
            </div>
          </div>
        </div>

        {false && (
          <div className="flex flex-col ">
            <span className="text-white font-archivo text-[40px] leading-[40px] mb-[80px] text-center">
              Currently On Sale
            </span>
            <div className="flex"></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;
